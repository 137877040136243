import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import {
  type ConfirmAttendancePayload,
  useConfirmAttendance,
} from "@src/appV2/Shifts/UrgentShifts/api/useConfirmAttendance";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useForm } from "react-hook-form";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";

import { getSuggestedEta } from "./helpers";
import { type UpdateEtaFormFields, updateEtaFormSchema } from "./schema";

interface UseEtaUpdateFormProps {
  shift: Pick<Shift, "_id" | "start" | "name">;
  maxEtaBoundInMinutes: number;
}

export function getEtaUpdateFormDefaultValues(props: UseEtaUpdateFormProps) {
  const { maxEtaBoundInMinutes, shift } = props;
  return {
    eta: getSuggestedEta({ shiftStart: parseISO(shift.start) }),
    notes: undefined,
    metadata: {
      maxEtaBoundInMinutes,
      shiftStart: shift.start,
    },
  };
}

export function useEtaUpdateForm(props: UseEtaUpdateFormProps) {
  const { shift } = props;
  const sendBirdStateContext = useSendbirdStateContext();
  const worker = useDefinedWorker();
  const { showSuccessToast } = useToast();

  const { mutateAsync: onFormSubmit } = useConfirmAttendance();

  const shiftStart = shift.start;
  const agentTimezone = worker.tmz ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formMethods = useForm<UpdateEtaFormFields>({
    defaultValues: getEtaUpdateFormDefaultValues(props),
    resolver: zodResolver(updateEtaFormSchema),
  });

  function showEtaUpdateSuccessToast(params: {
    shiftStart: string;
    agentTimezone: string;
    shiftName: string;
  }) {
    const { shiftStart, agentTimezone, shiftName } = params;
    showSuccessToast(
      `You successfully updated your ETA for your ${formatInTimeZone(
        parseISO(shiftStart),
        agentTimezone,
        "MM/dd"
      )} ${shiftName.toUpperCase()} shift`
    );
  }

  async function onFormSuccess(params: {
    payload: ConfirmAttendancePayload;
    messageToFacility: string;
  }) {
    const { payload, messageToFacility } = params;
    const sdk = sendBirdStateContext?.stores?.sdkStore?.sdk;
    const sendUserMessage = sendBirdSelectors.getSendUserMessage(sendBirdStateContext);

    showEtaUpdateSuccessToast({
      shiftStart,
      agentTimezone,
      shiftName: shift.name,
    });
    if (sdk?.UserMessageParams && isDefined(payload.workplaceId)) {
      const params = new sdk.UserMessageParams();

      params.message = messageToFacility;
      await sendUserMessage(`${payload.workplaceId}_${worker.userId}`, params);
    }

    logEvent(APP_V2_USER_EVENTS.SUBMITTED_ETA_MODAL, {
      shiftId: shift._id,
      workerId: worker.userId,
      facilityId: payload.workplaceId,
      channel: payload.channel,
      shiftStart,
      createdAt: new Date().toISOString(),
      etaSubmitted: payload.eta,
      messageToFacility,
    });
  }

  return {
    onFormSubmit,
    onFormSuccess,
    formMethods,
    agent: worker,
    agentTimezone,
    showEtaUpdateSuccessToast,
  };
}
