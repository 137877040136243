import { Text } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { DateTimePicker } from "@src/appV2/lib/DatePicker";
import { addMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useController } from "react-hook-form";

import { type UpdateEtaFormFields } from "./schema";

interface Props {
  timezone?: string;
  maxEtaBoundInMinutes: number;
  shiftStart: Date;
}

export function EtaTimePicker(props: Props) {
  const {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    maxEtaBoundInMinutes,
    shiftStart,
  } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController<UpdateEtaFormFields>({ name: "eta" });

  const localShiftStart = utcToZonedTime(shiftStart, timezone);
  const maxEtaTime = addMinutes(localShiftStart, maxEtaBoundInMinutes + 1); // maxDateTime is exclusive

  const now = utcToZonedTime(new Date(), timezone);
  const minDateTime = now > localShiftStart ? now : localShiftStart;

  const localFieldValue =
    field.value instanceof Date ? utcToZonedTime(field.value, timezone) : undefined;

  return (
    <Box>
      <Text variant="subtitle2" id="eta-label">
        Expected arrival time
      </Text>
      <DateTimePicker
        {...field}
        minDateTime={minDateTime}
        minuteStep={5}
        maxDateTime={maxEtaTime}
        disabled={isSubmitting}
        initialValue={localFieldValue}
        onConfirm={(newValue) => {
          const dateWithoutSeconds = newValue ? new Date(newValue.setSeconds(0, 0)) : null;
          const utcValue = dateWithoutSeconds ? zonedTimeToUtc(dateWithoutSeconds, timezone) : null;
          field.onChange(utcValue);
        }}
      />
    </Box>
  );
}
