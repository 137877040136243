import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useIonAppContext } from "@src/appV2/ionic";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import {
  type ConfirmAttendanceErrorResponse,
  type ConfirmAttendancePayload,
} from "@src/appV2/Shifts/UrgentShifts/api/useConfirmAttendance";
import {
  AttendanceConfirmationChannel,
  ConfirmAttendanceResponseCode,
} from "@src/appV2/Shifts/UrgentShifts/constant";
import { type AxiosError } from "axios";
import { parseISO } from "date-fns";
import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { AdditionalNotes } from "./AdditionalNotes";
import { EtaTimePicker } from "./EtaTimePicker";
import { getEtaMessage } from "./helpers";
import { type UpdateEtaFormFields } from "./schema";
import { getEtaUpdateFormDefaultValues, useEtaUpdateForm } from "./useEtaUpdateForm";

const FORM_ID = "updateEtaForm";

interface UpdateEtaDialogProps {
  shift: Shift;
  modalState: UseModalState;
  sendbirdMessageId?: number;
  channel?: AttendanceConfirmationChannel;
  maxEtaBoundInMinutes: number;
}
export function UpdateEtaDialog(props: UpdateEtaDialogProps) {
  const { shift, modalState, sendbirdMessageId, channel, maxEtaBoundInMinutes } = props;

  const { showErrorToast } = useToast();

  const history = useHistory();

  const { onFormSubmit, onFormSuccess, formMethods, agentTimezone, showEtaUpdateSuccessToast } =
    useEtaUpdateForm({
      shift,
      maxEtaBoundInMinutes,
    });

  const {
    handleSubmit: onSubmit,
    watch,
    formState: { isSubmitting },
  } = formMethods;

  const eta = watch("eta");

  const etaMessage = getEtaMessage({
    shiftStart: parseISO(shift.start),
    eta: eta ?? new Date(),
    timeZone: agentTimezone,
  });

  const {
    ionAppRef: { current: ionApp },
  } = useIonAppContext();

  return (
    <Dialog
      open={modalState.modalIsOpen}
      container={ionApp}
      aria-label="Update your ETA"
      onClose={() => {
        /**
         * FIXME, break apart the dialog to move the form to be a separate component in a separate file.
         */
        formMethods.reset(getEtaUpdateFormDefaultValues({ shift, maxEtaBoundInMinutes }));
        modalState.closeModal();
      }}
    >
      <DialogTitle
        component="h2"
        variant="h3"
        sx={{
          paddingBottom: 0,
        }}
      >
        Update your ETA
      </DialogTitle>
      <IconButton
        aria-label="Close dialog"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        onClick={modalState.closeModal}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText paragraph variant="body2">
          Let {shift?.facility?.name} know you&apos;ll be late and when you expect to arrive for
          your shift.
        </DialogContentText>
        <DialogContentText paragraph variant="body2">
          Message: &quot;{etaMessage}&quot;
        </DialogContentText>
        <FormProvider {...formMethods}>
          <Stack
            useFlexGap
            spacing={3}
            component="form"
            id={FORM_ID}
            onSubmit={onSubmit(async (formData: UpdateEtaFormFields) => {
              try {
                const { eta, notes } = formData;
                if (!isDefined(eta)) {
                  return false;
                }

                const payload: ConfirmAttendancePayload = {
                  shiftId: shift._id,
                  eta: eta.toISOString(),
                  channel: channel ?? AttendanceConfirmationChannel.MY_SHIFT_PAGE,
                  workplaceId: shift.facility.userId,
                  sendbirdMessageId,
                };
                await onFormSubmit(payload);
                const messageToFacility = [etaMessage];
                if (isDefined(notes) && notes.length > 0) {
                  messageToFacility.push(notes);
                }

                await onFormSuccess({ payload, messageToFacility: messageToFacility.join(" ") });
                formMethods.reset();
                modalState.closeModal();
                return true;
              } catch (error) {
                const body = (error as AxiosError<ConfirmAttendanceErrorResponse>)?.response?.data;
                // Swallow the error if the shift is not eligible for ETA updates, as this
                // is not actionable by the worker. We could tell the worker that the shift is
                // not eligible for ETA updates, but that risks losing the incentive to arrive
                // as soon as possible.
                if (
                  body?.type === ConfirmAttendanceResponseCode.SHIFT_NOT_ELIGIBLE_FOR_ETA_UPDATES
                ) {
                  showEtaUpdateSuccessToast({
                    shiftStart: shift.start,
                    agentTimezone,
                    shiftName: shift.name,
                  });
                } else {
                  showErrorToast(body?.message ?? "Unable to confirm attendance");
                }

                logError(APP_V2_APP_EVENTS.UPDATE_YOUR_ETA_FAILURE, { error });

                if (
                  isDefined(body?.type) &&
                  [
                    ConfirmAttendanceResponseCode.REPLACEMENT_FOUND,
                    ConfirmAttendanceResponseCode.SHIFT_NOT_ELIGIBLE_FOR_ETA_UPDATES,
                  ].includes(body.type)
                ) {
                  modalState.closeModal();
                  history.push("/home/myShifts");
                }

                return false;
              }
            })}
          >
            <EtaTimePicker
              maxEtaBoundInMinutes={maxEtaBoundInMinutes}
              timezone={agentTimezone}
              shiftStart={parseISO(shift.start)}
            />
            <AdditionalNotes name="notes" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          size="large"
          sx={{ width: "100%" }}
          form={FORM_ID}
        >
          Update ETA&nbsp;{isSubmitting ? <CircularProgress size={20} /> : null}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
